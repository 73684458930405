import React from 'react'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react'
import { DOPPEL_BREACH_RED, DOPPEL_GREY_900 } from '@/utils/style'

const DoppelAlertDialog = ({
  isOpen,
  onClose,
  cancelRef,
  header,
  body,
  confirmAction,
  confirmLabel = 'Confirm',
  cancelLabel = 'Cancel',
  bgColor = DOPPEL_BREACH_RED,
}) => {
  return (
    <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
      <AlertDialogOverlay>
        <AlertDialogContent
          backgroundColor={DOPPEL_GREY_900}
          margin="auto"
          marginTop="calc(50vh - 4rem)"
          maxWidth="500px"
          width="90%"
        >
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {header}
          </AlertDialogHeader>

          <AlertDialogBody>{body}</AlertDialogBody>

          <AlertDialogFooter>
            <Button onClick={onClose} ref={cancelRef}>
              {cancelLabel}
            </Button>

            <Button bg={bgColor} ml={3} onClick={confirmAction}>
              {confirmLabel}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

export default DoppelAlertDialog
